interface UserReview {
  author: string;
  comment: string;
}

export const userReviews: UserReview[] = [
  {
    author: 'Emily J.',
    comment:
      'Mindway helped me better myself and my mental health. Thank you, I feel lucky to have found this.',
  },
  {
    author: 'Sue',
    comment:
      'Fantastic way to clear your mind. The meditation sections are really good.',
  },
  {
    author: 'Anthony Brown',
    comment: `Overthinking is not a joke. Mindway really solved the majority of my mental problems.`,
  },
  {
    author: 'Luke',
    comment:
      'Perfect for those who suffer from brain fog and overthinking! Helped me a lot!',
  },
  {
    author: 'Mike S.',
    comment:
      'It gave me a new perspective on my collapsing relationship. Now we’re happy together!',
  },
  {
    author: 'Emeral',
    comment:
      'Great app for people who work a lot, it helped me stay sharp. The meditations help me unwind.',
  },
];
