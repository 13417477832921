interface VideoReview {
  url: string;
  title: string;
  description: string;
  name: string;
}

export const videoReviews: VideoReview[] = [
  {
    url: 'https://res.cloudinary.com/drfweekiv/video/upload/v1722327157/mindway-1.mp4',
    title: 'Helps me relax',
    description: `"Mindway has been a lifesaver for managing my stress. I have a very demanding job, and this app helps me unwind & control my emotions."`,
    name: 'Hannah B.',
  },
  {
    url: 'https://res.cloudinary.com/drfweekiv/video/upload/v1722327157/mindway-5.mp4',
    title: 'I’m less critical of myself',
    description: `"Mindway helped me manage my overthinking and procrastination. I’ve become more productive and less critical of myself."`,
    name: 'Michael L.',
  },
  {
    url: 'https://res.cloudinary.com/drfweekiv/video/upload/v1722327157/mindway-2.mp4',
    title: 'Easy to use app',
    description: `"Great app for mindfulness and mental wellness. The exercises are practical and easy to follow, and it has helped me stay calm."`,
    name: 'Rachel K.',
  },
  {
    url: 'https://res.cloudinary.com/drfweekiv/video/upload/v1722327157/mindway-3.mp4',
    title: 'Cured my insomnia',
    description: `"I used to struggle with an overactive mind at night. Mindway's sleep focused relaxations help me stop racing thoughts & calm down."`,
    name: 'Lola M.',
  },
  {
    url: 'https://res.cloudinary.com/drfweekiv/video/upload/v1722327157/mindway-4.mp4',
    title: 'Love guided meditations',
    description: `"Mindway really helps stop overthinking. I often think, 'Wow, that's me!' It offers great insights into managing my thoughts."`,
    name: 'Jasmine',
  },
];
