interface SuccessStory {
  imgUrl: string;
  story: string;
  author: string;
}

export const SuccessStories: SuccessStory[] = [
  {
    imgUrl:
      'https://res.cloudinary.com/drfweekiv/image/upload/v1722327307/Frame_1214134128_20.png',
    story: `"I struggled with high stress and anxiety from a recent job loss. I had financial issues, low self-esteem, and many other challenges. Mindway gave me a sense of calmness and basically solved most of my problems."`,
    author: 'Mark R., 28',
  },
  {
    imgUrl:
      'https://res.cloudinary.com/drfweekiv/image/upload/v1722327307/Frame_1214134128_21.png',
    story:
      '"Moving to another state has been super stressful, but Mindway has been a lifesaver. The guided meditations help me calm down when it all gets overwhelming. I feel more balanced and happier despite the chaos."',
    author: 'Ava Dickinson,  31',
  },
  {
    imgUrl:
      'https://res.cloudinary.com/drfweekiv/image/upload/v1722327307/Frame_1214134128_22.png',
    story:
      '"Since I broke up with my boyfriend, my mental health was in ruins. This app keeps me in tune with my emotional ups and downs. I often felt overwhelmed with everything, but now I have a better handle on my emotions."',
    author: 'Emilia, 29',
  },
  {
    imgUrl:
      'https://res.cloudinary.com/drfweekiv/image/upload/v1722327307/Frame_1214134128_23.png',
    story:
      '"I’ve been using Mindway for a year and it truly helped me with my depression and the constant feeling of hopelessness. The app helped me become more positive and have a better sense of my emotional state."',
    author: 'Jason B., 26',
  },
  {
    imgUrl:
      'https://res.cloudinary.com/drfweekiv/image/upload/v1722327308/Frame_1214134128_24.png',
    story: `"Mindway helped me manage my constant overthinking and feeling like I don’t belong. I've been shy and introverted all my life, but now I can finally see my self-esteem and confidence growing. I even feel more sociable around new people."`,
    author: 'Lilith H., 27',
  },
  {
    imgUrl:
      'https://res.cloudinary.com/drfweekiv/image/upload/v1722327307/Frame_1214134128_25.png',
    story:
      '"Mindway has been a lifeline for me in dealing with my loneliness. Before, I often felt isolated and alone. Now, it’s become an essential part of my self-care routine. It truly helped me manage my loneliness and improve my mental well-being."',
    author: 'Samantha L., 24',
  },
];
